@import '~antd/dist/antd.css';
/* ---------------------------------- FONTS --------------------------------- */

@font-face {
  /* font-family: 'Roboto';/ */
  /* src: url('../font/Roboto/Roboto-Regular.eot'); */
  /* src: local('☺'), url('../font/Roboto/Roboto-Regular.woff') format('woff'), url('../font/Roboto/Roboto-Regular.ttf') format('truetype'), */
  /* url('../font/Roboto/Roboto-Regular.svg') format('svg'); */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  /* font-family: 'Roboto'; */
  /* src: url('../font/Roboto/Roboto-Bold.eot'); */
  /* src: local('☺'), url('../font/Roboto/Roboto-Bold.woff') format('woff'), url('../font/Roboto/Roboto-Bold.ttf') format('truetype'), */
  /* url('../font/Roboto/Roboto-Bold.svg') format('svg'); */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../font/Inter/Inter-Bold.ttf');
  font-weight: 600;
  font-style: bold;
}

/* ---------------------------------- FONTS END --------------------------------- */

#root {
  background: #f1f1f1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.sweet-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* login */
#login .ant-form-item-control-input {
  width: 100%;
  margin: auto;
}

button {
  color: white;
}

.menu-collapsed {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}

.blured {
  filter: blur(3px);
}

.react-tabs {
  margin: 0.625rem;
}

.text-center {
  text-align: center !important;
}

.py-10 {
  padding: 10px 0 !important;
}

::-webkit-scrollbar {
  width: 0.2375rem;
  height: 0.2375rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.3125rem grey;
  border-radius: 0.625rem;
}

svg {
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6ea6d7;
  border-radius: 0.625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6ea6d7;
}

.none-scroll .ant-layout-content::-webkit-scrollbar {
  display: none;
}

.scroll .ant-layout-content::-webkit-scrollbar {
  display: block;
}

ul,
ol {
  padding-left: 0px;
}

div.error {
  color: red;
  opacity: 0.9;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.user-image:active,
.user-image:visited,
.user-image:focus {
  color: dodgerblue !important;
}

.ant-dropdown,
.ant-dropdown-placement-bottomLeft {
  height: 100%;
  top: 78px;
}

body.A4 .sheet {
  padding-top: 15px;
  z-index: 1000;
}

body.A4 .sheet .template-header>div:nth-child(2) {
  width: 80%;
}

body.A4 .sheet h3 {
  font-size: 1rem;
  font-weight: bolder;
}

body.A4 .sheet table {
  table-layout: fixed;
  width: auto;
}

body.landscape .sheet footer {
  margin-top: 0px;
  height: 180px;
}

.active-btn {
  border: 1px solid #727272 !important;
}

/* .react-datepicker__input-container,.react-datepicker-wrapper{
=======

/*Pulse custom input*/
.w-40 {
  width: 40px !important;
}

.devCheckbox {
  position: relative;
  cursor: pointer;
}

.devCheckbox input[type='checkbox'] {
  visibility: hidden;
  z-index: 0;
}

.devCheckbox .main {
  cursor: pointer;
}

.width15 {
  width: 15% !important;
}

.devCheckbox .geekmark {
  position: absolute;
  border: 1px solid #999;
  /*top: 12px;*/
  left: 9px;
  height: 20px;
  width: 20px;
  /* border: 1px solid grey; */
  background-color: #ffff;
}

.devCheckbox .main input:active~.geekmark {
  background-color: #2977FF;
  border: 1px solid white !important;
}

.devCheckbox .main input:checked~.geekmark {
  background-color: #2977FF;
}

.devCheckbox .geekmark:after {
  content: '';
  position: absolute;
  display: none;
}

.devCheckbox .main input:checked~.geekmark:after {
  display: none;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  /*overflow: visible!important;*/
  display: flex !important;
}

.ant-modal-body {
  text-align: initial !important;
}

.devCheckbox .main .geekmark:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: 1px solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*.ant-menu-submenu-title{padding-left: 10px!important;}*/
/*Pulse custom input end*/
.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100% !important;
}

.ant-input-affix-wrapper-focused input::placeholder {
  color: white !important;
}

.ant-input-affix-wrapper-focused {
  border: 1px solid white !important;
}

.flex-list-select {
  /* padding-right: 3px; */
}

/* chechboxes */

.create-form-checkbox {
  width: 25px;
  height: 25px;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

input:disabled,
textarea:disabled,
select:disabled {
  background-color: #c4c4c4;
}

input.summary-sum:disabled {
  background-color: white;
}

.flex-list-select input {
  border-color: white;
  border-right-width: 1px !important;
  outline: 0;
  /* box-shadow: 0 0 0 2px white; */
}

/* antd select dropdown style */
.dark-dropdown,
.dark-dropdown .ant-select-item {
  background: #001529;
}

.dark-dropdown .ant-select-item {
  height: 75px !important;
  display: flex;
  align-items: center;
}

.dark-dropdown,
.dark-dropdown .ant-select-item:hover {
  background: rgb(114, 114, 114) !important;
  color: #FFFFFF;
}

.dark-dropdown .ant-select-item:hover {
  background: rgb(114, 114, 114);
  color: #FFFFFF;
}

.dark-dropdown {
  padding: 0;
}

.dark-dropdown .ant-select-item:hover span {
  color: #FFFFFF;
}

.dark-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.dark-dropdown.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: rgb(114, 114, 114);
  padding: 0.5rem 1rem;
}

/* Select dropdown new ui style */
.white-dropdown,
.white-dropdown .ant-select-item {
  background: #FFFFFF;
}

.white-dropdown .ant-select-item {
  height: 75px !important;
  display: flex;
  align-items: center;
}

.white-dropdown,
.white-dropdown .ant-select-item:hover {
  background: #E7F8E7 !important;
  color: #000c17;
}

.white-dropdown .ant-select-item:hover {
  background: #E7F8E7;
  color: #000c17;
}

.white-dropdown {
  padding: 0;
}

.white-dropdown .ant-select-item:hover span {
  color: #000c17;
}

.white-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.white-dropdown.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #FFFFFF;
  padding: 0.5rem 1rem;
}

.not-table>.table-bottom {
  /* padding-right: 0.625rem; */
}

.not-table .recipient-field {
  /* padding-right: 10px; */
}

/*ForReactDataPicker*/
.react-datepicker {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day-names {
  background: white !important;
}

.react-datepicker__header {
  border: none !important;
  background: white !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: white !important;
  color: #62a7dc !important;
  border: 1px solid #62a7dc;
}

.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: white !important;
  color: #62a7dc !important;
  border: 1px solid #62a7dc;
}

.react-datepicker__navigation--previous {
  border-right-color: #2977FF !important;
}

.react-datepicker__navigation--next {
  border-left-color: #2977FF !important;
}

.ReactVirtualized__Grid {
  outline: none;
}

/*NOTE CHECKBOX HOVER */

input[type='checkbox']:hover {
  cursor: pointer;
}

/*NOTE INCOME OUTGO TABLE STYLE */

.table-outbox-inbox .ant-select {
  width: calc(100% - 10px) !important;
  width: --webkit-calc(100% - 10px);
}

.table-outbox-inbox .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid black;
}

.table-outbox-inbox {
  font-size: 12px;
}

.not-valid {
  border-color: #dc3545 !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector input:focus,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border: none;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap-3 {
  gap: 30px;
}

.mr-1 {
  margin-right: 14px;
}

.ml-1 {
  margin-left: 14px;
}

.ml-auto {
  margin-left: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ta-r {
  text-align: right;
}

/* qator sonini belgilash */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* word-break: normal; */
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*ForReactDataPicker*/