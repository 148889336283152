.ant-collapse-header {
  background-color: #fff;
}

.ant-collapse-header .ant-collapse-expand-icon {
  margin-top: 5px;
}

.ant-collapse-header .ant-collapse-expand-icon .ant-collapse-arrow {
  font-size: 22px !important;
  color: #2977FF;
  font-weight: 700;
}

.compares-list .ant-collapse-header .ant-collapse-expand-icon .ant-collapse-arrow {
  color: #115cef;
}

.compares-list > .ant-collapse {
  border-radius: 0 0 15px 15px;
}
.compares-list .ant-collapse > .ant-collapse-item:last-child,
.compares-list .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 15px 15px;
}
/* .compares-list .ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0 0 15px 15px;
} */
/* .compares-list {
  border-radius: 0 0 15px 15px;
}
.compares-list .compares-list-wrapper {
  border-radius: 0 0 15px 15px;
}
 */
.ant-collapse-header .ant-collapse-header-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}