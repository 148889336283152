.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  outline: none;
  border: none;
}

.react-datepicker__input-container {
  background-image: none;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 2px;   */
}

.react-datepicker__input-container > input::placeholder {
  color: #c4c4c4;
}
