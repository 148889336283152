/* GLOBAL */

:root {
  --primary: #115cef;
  --secondary: #76767b;
  --black: #090909;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

*:focus,
*::after:focus,
*::before:focus {
  outline: 1px dashed var(--primary) !important;
  outline-offset: 3px !important;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: 0.15px;
  background-color: #fff;
  color: var(--black);
  overflow-x: hidden;
  box-sizing: border-box;
  scroll-behavior: smooth;
  min-height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.languageSelector-item {}

#myLanguageSelector,
#myLanguageSelector2 {
  border: 0;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#myLanguageSelector option {
  background-color: #ffffff;
}

@media (max-width: 991.98px) {

  #myLanguageSelector,
  #myLanguageSelector2 {
    padding: 2px 5px 2px 0;
  }
}

#myLanguageSelector:focus,
#myLanguageSelector2:focus {
  outline: 0;
  box-shadow: none;
}

/* CONTAINER */
.container {
  max-width: 100%;
  width: 1260px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* MAIN CONTENT */
.main-content {
  flex-grow: 1;
}

.cta-btn {
  padding: 16px 15px;
  display: inline-block;
  border-radius: 15px;
  background-color: var(--primary);
  color: #fff !important;
  text-decoration: none;
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.cta-btn-outlined {
  padding: 13px 15px;
  display: inline-block;
  border-radius: 15px;
  border: 2px solid var(--primary);
  background-color: #FFF;
  color: var(--primary) !important;
  text-decoration: none;
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.cta-btn::first-letter {
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .cta-btn {
    padding: 16px 9px;
    font-size: 14px;
    line-height: 1.14;
  }
}

#downloadPDF {
  cursor: pointer;
  text-decoration: underline;
}

.site-header {
  padding: 24px 0 32px;
}

.site-header__container {
  display: flex;
  justify-content: space-between;
}

.site-header__logo-link {
  width: 155px;
  flex-shrink: 0;
}

.site-header__list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0;
  list-style: none;
}

.site-header__link {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: none;
  letter-spacing: 0.5px;
  color: rgb(9, 9, 9);
}

.site-header__link__description {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  letter-spacing: 0.5px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: rgb(9, 9, 9);
}

.site-header__link__description>span {
  position: relative;
  bottom: 6px;
}

.logIn-btn {
  display: none;
}

.site-header .burger-toggle {
  display: none;
  position: relative;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (max-width: 991px) {
  .site-header__list {
    display: none;
  }

  .site-header__container {
    align-items: center;
  }

  .logIn-btn {
    display: block;
  }

  .site-header .burger-toggle {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 30px;
    height: 30px;
    margin-top: 12px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
  }

  .site-header .burger-toggle:before,
  .site-header .burger-toggle:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--black);
    display: block;
    content: '';
  }

  .site-header .burger-toggle:before {
    transform-origin: left bottom;
    top: 4px;
  }

  .site-header .burger-toggle:after {
    transform-origin: left top;
    bottom: 4px;
  }

  .site-header .burger-toggle span {
    width: 70%;
    height: 3px;
    background-color: var(--black);
  }
}

.site-footer {
  padding: 80px 0 10px;
  background-color: #f5f6f6;
}

.site-footer__logo-link {
  max-width: 180px;
  width: 100%;
  display: inline-block;
  margin-bottom: 55px;
}

.site-footer__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.site-footer__nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 30px;
  margin-bottom: 24px;
}

.site-footer__nav-item {
  font-size: 14px;
  line-height: 1.14;
}

.site-footer__nav-link {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  text-decoration: none;
  color: var(--black);
}

.site-footer__nav-link:hover {
  text-decoration: underline;
}

.site-footer__contact-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 30px;
}

.site-footer__contact-item:first-child .site-footer__contact-link {
  text-decoration: underline;
}

.site-footer__contact-link {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  text-decoration: none;
  color: var(--black);
}

.site-footer__contact-link:hover {
  text-decoration: underline;
}

.site-footer__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.site-footer__social-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  margin-top: 0;
  margin-bottom: 24px;
}

.site-footer__social-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 20px;
}

.site-footer__social-link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  border-radius: 8px;
}

.site-footer__social-link svg {
  fill: #fff;
  width: 16px;
  height: 16px;
}

.site-footer__docs-top {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 60px;
}

.site-footer__docs-top span,
.site-footer__docs-top a {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.14;
  color: #666;
  display: inline-block;
}

.site-footer__copyright {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.14;
  color: #666;
  text-align: center;
  margin: 0;
}

@media (max-width: 1030px) {
  .site-footer__docs-top {
    flex-direction: column;
    gap: 30px;
  }

  .site-footer__docs-top span {
    order: 3;
  }

  .site-footer__docs-top a {
    order: 1;
  }

  .site-footer__docs-top a:last-child {
    order: 2;
  }
}

@media (max-width: 767px) {
  .site-footer__content {
    flex-direction: column;
  }

  .site-footer__nav-list {
    flex-wrap: wrap;
  }

  .site-footer__logo-link {
    margin-bottom: 40px;
  }

  .site-footer__contact-list {
    margin-bottom: 40px;
    row-gap: 0;
    flex-wrap: wrap;
  }

  .site-footer__right {
    align-items: flex-start;
  }
}

.hero {
  padding: 30px 0 0;
  position: relative;
  overflow: hidden;
}

.hero__bg {
  position: absolute;
  top: 100px;
  width: 100%;
  z-index: 10;
}

.hero__bg-img {
  width: 100%;
}

.hero__star {
  position: absolute;
}

.hero__star-blue {
  top: 50%;
  left: 12%;
  transform: translateY(-50%);
}

.hero__star-small {
  top: 24px;
  right: 14%;
}

.hero__star-gray {
  top: 50px;
  right: 10%;
}

.hero__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 200;
}

.hero__title {
  font-size: 52px;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-family: 'Inter';
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 14px;
  text-align: center;
  color: #000;
}

@media (max-width: 991px) {
  .hero__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .hero__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.hero__title span {
  color: var(--primary);
}

.hero__subtitle {
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  color: #000 !important;
  letter-spacing: -0.1px;
  line-height: 1.6;
  text-align: center;
  margin-top: 0;
  margin-bottom: 18px;
}

.hero__cta-btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1;
  text-align: center;
  min-width: 120px;
}

.hero__brands-img {
  width: 140px;
  height: auto;
  filter: grayscale(100%);
  opacity: 0.6;
  transition: opacity 0.3s ease, filter 0.3s ease;
  filter: none;
  opacity: 1;
}

.hero__brands-img:hover {
  filter: none;
  opacity: 1;
}

.hero .swiper-cont {
  /* margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px; */
  position: relative;
  z-index: 200;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
}

.hero .swiper-cont .swiper {
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.hero .swiper-wrapper {
  display: flex;
  height: 100%;
  width: 300%;

  @media (max-width:800px) {
    gap: 25px;
  }
}

.hero .swiper-slide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #343434;
  animation: translatestf 70s linear infinite;
}

@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-500%);
  }
}

.hero__img-wrapper {
  background-image: linear-gradient(to top, #1c1c1e 40%, transparent 40%);
  text-align: center;
  position: relative;
  z-index: 200;
}

.hero__img-inner {
  max-width: 1100px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}

.hero__img {
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 20px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

@media (max-width: 767px) {
  .hero {
    padding-top: 80px;
  }

  .hero__title {
    font-size: 32px;
    letter-spacing: 1.5px;
    text-align: left;
  }

  .hero__subtitle {
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
  }

  .hero__cta-btn {
    width: 100%;
    text-align: center;
  }

  .hero__bg {
    display: none;
  }
}

.second {
  background-color: #1c1c1e;
  padding: 145px 0;
}

.second__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 55px;
  color: #fff;
}

@media (max-width: 991px) {
  .second__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .second__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.second__title span {
  color: var(--primary);
}

@media (max-width: 767px) {
  .second {
    padding: 60px 0;
  }

  .second__cta-btn {
    width: 100%;
    text-align: center;
  }
}

.third {
  position: relative;
  background-color: #f5f6f6;
  padding: 145px 0;
}

.third .container {
  position: relative;
  z-index: 3;
}

.third__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 55px;
}

@media (max-width: 991px) {
  .third__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .third__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.third__title span {
  color: var(--primary);
}

.third__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.third__item {
  max-width: 580px;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 35px;
}

.third__item:last-child {
  margin-bottom: 0;
}

.third__item-img-wrapper {
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px 20px 0 20px;
}

.third__item-img-wrapper img {
  width: 45px;
  filter: drop-shadow(10px 7px 7px rgba(0, 0, 0, 0.2));
}

.third__img {
  position: absolute;
  right: 0;
  top: 145px;
  z-index: 1;
  transform: translateX(30%);
  max-width: 1000px;
}

@media (max-width: 1170px) {
  .third__img {
    opacity: 0.6;
  }
}

@media (max-width: 991px) {
  .third__img {
    display: none;
  }
}

@media (max-width: 767px) {
  .third {
    padding: 60px 0;
  }

  .third__item {
    font-size: 16px;
    letter-spacing: 0.5px;
    gap: 20px;
  }
}

@keyframes opacity01 {
  0% {
    opacity: 0;
  }

  110% {
    opacity: 1;
  }
}

@keyframes opacity02 {
  0% {
    opacity: 0;
  }

  110% {
    opacity: 1;
  }
}

.fourth {
  padding: 145px 0 0;
}

.fourth__container {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.fourth__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 55px;
}

@media (max-width: 991px) {
  .fourth__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .fourth__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.fourth__title span {
  color: var(--primary);
}

.fourth__toggle-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.fourth__toggle-item {
  border-radius: 15px;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.5px;
  border: 0;
  background-color: #f5f6f6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease;
}

.fourth__toggle-item.active {
  padding: 8px 16px;
  background-color: var(--primary);
  color: #fff;
}

.fourth__img-wrapper {
  width: 100%;
  display: none;
}

.fourth__img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: opacity01 0.6s ease;
}

.fourth__img.animate {
  animation: opacity02 0.6s ease;
}

@media (max-width: 767px) {
  .fourth {
    padding-top: 60px;
  }

  .fourth__title {
    margin-bottom: 30px;
  }

  .fourth__toggle-list {
    margin-bottom: 50px;
  }
}

.fifth {
  margin-bottom: 145px;
}

.fifth__list {
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.fifth__item {
  width: calc((100% - 60px) / 3);
  min-height: 300px;
}

.fifth__card {
  padding: 48px 24px;
  border-radius: 15px;
  background-color: #f5f6f6;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fifth__card-img-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.fifth__card-img-wrapper img {
  width: 54px;
  height: auto;
  color: #fff;
  fill: #fff !important;
}

.fifth__card-img-wrapper span {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--primary);
}

.fifth__card-img img {
  width: 54px;
  height: auto;
  display: block;
}

.fifth__card-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 16px;
}

.fifth__card-text {
  color: #76767b;
  margin: 0;
}

.fifth__card-text span {
  color: var(--primary);
}

@media (max-width: 1199px) {
  .fifth__list {
    flex-wrap: wrap;
  }

  .fifth__item {
    width: calc((100% - 30px) / 2);
  }
}

@media (max-width: 814px) {
  .fifth {
    margin-bottom: 60px;
  }

  .fifth__list {
    justify-content: center;
  }

  .fifth__item {
    max-width: 450px;
    width: 100%;
    font-size: 18px;
  }
}

.sixth {
  background-color: #1c1c1e;
  padding: 125px 0;
  position: relative;
  overflow: hidden;
}

.sixth__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  max-width: 800px;
  margin-top: 0;
  margin-bottom: 55px;
  color: #fff;
}

@media (max-width: 991px) {
  .sixth__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .sixth__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.sixth__title span {
  color: var(--primary);
}

.sixth__list {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sixth__item {
  padding: 16px 48px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 1.6;
  border-radius: 15px;
  background-color: #38383d;
  color: #dcdcdc;
}

.sixth__img {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
}

@media (max-width: 767px) {
  .sixth {
    padding: 60px 0;
  }

  .sixth__img {
    width: 250px;
  }

  .sixth__item {
    padding: 11px;
    flex-grow: 1;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.eighth {
  padding: 145px 0;
  background-color: #f5f6f6;
}

.eighth__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 55px;
  max-width: 440px;
}

@media (max-width: 991px) {
  .eighth__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .eighth__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.eighth__title span {
  color: var(--primary);
}

.eighth__list {
  display: flex;
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.eighth__item {
  width: calc((100% - 60px) / 3);
}

.eighth__item:last-child .eighth__card {
  background-color: var(--primary);
}

.eighth__item:last-child .eighth__card-title {
  color: #fff;
}

.eighth__item:last-child .eighth__card-number {
  color: #fff;
  filter: drop-shadow(-9px -7px 0 rgba(242, 242, 242, 0.2));
}

.eighth__card {
  padding: 50px 32px;
  border-radius: 15px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 400px;
}

.eighth__card-number {
  display: block;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.2;
  color: var(--primary);
  margin-bottom: 28px;
  filter: drop-shadow(-9px -7px 0 rgba(17, 92, 239, 0.15));
}

.eighth__card-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 16px;
}

.eighth__card-text {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 1.6;
  margin: 0;
  color: var(--secondary);
}

.eighth__card-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.eighth__card-star-1 {
  position: absolute;
  top: 20px;
  right: 20%;
}

.eighth__card-star-2 {
  position: absolute;
  top: 40px;
  right: 10%;
}

.eighth__card-star-3 {
  position: absolute;
  top: 60px;
  right: 15%;
}

@media (max-width: 1199px) {
  .eighth__list {
    flex-wrap: wrap;
  }

  .eighth__item {
    width: calc((100% - 30px) / 2);
  }
}

@media (max-width: 799px) {
  .eighth {
    padding: 60px 0;
  }

  .eighth__item {
    width: 100%;
  }

  .eighth__card-text {
    font-size: 16px;
  }
}

.form-section {
  padding: 125px 0;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}

.form-section__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.form-section__title {
  font-size: 80px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 55px;
}

.form-section__title span {
  color: var(--primary);
}

.form-section__inputs-wrapper {
  display: flex;
  gap: 30px;
  margin-bottom: 48px;
}

.form-section__label {
  width: calc((100% - 30px) / 2);
  position: relative;
}

.form-section__label-title {
  display: block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.5;
  margin-bottom: 23px;
}

.form-section__label-title:after {
  color: var(--primary);
  content: '*';
}

.form-section__input {
  width: 100%;
  padding: 22px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  font-size: 16px;
  border: 1px solid rgba(102, 102, 102, 0.3);
  outline: 0;
  transition: border-color 0.3s ease;
}

.form-section__input::-moz-placeholder {
  color: rgba(102, 102, 102, 0.4);
}

.form-section__input::placeholder {
  color: rgba(102, 102, 102, 0.4);
}

.form-section__input:hover {
  border: 1px solid rgba(17, 91, 239, 0.3);
}

.form-section__input:focus {
  border: 1px solid var(--primary);
}

.form-section .error-input {
  border: 1px solid red !important;
}

.form-section .error-span {
  display: none;
}

.form-section .error-input+.error-span {
  display: inline-block;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 13px;
  color: red;
  font-style: italic;
}

.form-section__checkbox-wrapper {
  position: relative;
  max-width: 800px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 1.4;
  color: var(--black);
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-weight: 400;
  margin-bottom: 30px;
  cursor: pointer;
}

.form-section__checkbox-wrapper span {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.form-section__checkbox-wrapper a {
  text-decoration: underline;
  color: var(--black);
}

.form-section__checkbox-wrapper input {
  flex-shrink: 0;
}

.form-section__text {
  max-width: 800px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 1.4;
  color: var(--black);
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 30px;
}

.form-section__text a {
  color: var(--black);
}

.form-section__submit-btn {
  height: 68px;
  /*display: inline-block;*/
  padding: 25px;
  width: 380px;
  outline: 0;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.form-section__submit-btn .lds-spinner {
  display: none;
}

.form-section input::-webkit-outer-spin-button,
.form-section input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-section input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 991px) {
  .form-section__title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .form-section {
    padding: 60px 0;
  }

  .form-section__inputs-wrapper {
    flex-wrap: wrap;
    margin-bottom: 30px;
    gap: 40px;
  }

  .form-section__label {
    width: 100%;
  }

  .form-section__title {
    font-size: 32px;
    letter-spacing: 0.18px;
  }

  .form-section__submit-btn {
    width: 100%;
    padding: 16px;
    font-size: 14px;
    line-height: 1.14;
  }
}

@keyframes scaleBG {
  0% {
    width: 40px;
    height: 40px;
    opacity: 0;
    visibility: visible;
    border-radius: 15px;
    top: 50px;
    right: 50px;
  }

  25% {
    width: 120px;
    height: 120px;
    opacity: 0;
    visibility: visible;
    border-radius: 15px;
    top: 50px;
    right: 50px;
  }

  50% {
    top: 0;
    right: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
  }
}

@keyframes toTop {
  0% {
    margin-top: 30px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.burger-menu__bg-color {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary);
  visibility: hidden;
  margin-left: auto;
}

.burger-menu__bg-color.active {
  animation: scaleBG 0.7s ease-out;
}

.burger-menu {
  position: fixed;
  z-index: 999;
  top: 24px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  transition-delay: 0s;
}

.burger-menu.active {
  opacity: 1;
  visibility: visible;
  animation: toTop 0.5s ease;
  transition-delay: 0.4s;
}

.burger-menu * {
  position: relative;
  z-index: 3;
}

.burger-menu::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.burger-menu__inner {
  padding: 0 20px 20px;
}

.burger-menu__logo-link {
  width: 180px;
  display: block;
  margin-bottom: 30px;
}

.burger-menu__nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.burger-menu__nav-item {
  margin-bottom: 20px;
}

.burger-menu__nav-link {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  color: var(--black);
  text-decoration: none;
}

.burger-menu__cta-link {
  padding: 16px 15px;
  margin-bottom: 70px;
}

.burger-menu__socials-wrapper {
  margin-bottom: 30px;
}

.burger-menu__social-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  margin-top: 0;
  margin-bottom: 20px;
}

.burger-menu__social-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 20px;
}

.burger-menu__social-link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  border-radius: 8px;
}

.burger-menu__social-link svg {
  fill: #fff;
  width: 16px;
  height: 16px;
}

.burger-menu__footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.burger-menu__footer a {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  text-decoration: none;
  color: var(--black);
}

.burger-menu__footer a:nth-child(2) {
  text-decoration: underline;
}

.burger-menu__footer p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.14;
  color: #666;
}

.lds-spinner {
  /*color: official;*/
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-spinner div {
  transform-origin: 15px 15px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 1.125px;
  left: 13.875px;
  width: 2.25px;
  height: 6.375px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.pricing {
  padding: 120px 0 145px;
  background-color: #f5f6f6;
}

.pricing__top {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.pricing__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .pricing__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .pricing__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.pricing__title span {
  color: var(--primary);
}

.pricing__subtitle {
  font-size: 18px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 50px;
  max-width: 500px;
  text-align: center;
}

.pricing__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: stretch;
}

.pricing__item {
  width: calc((100% - 40px) / 4.7);
  height: auto;
  flex-direction: column;
}

.pricing__item:nth-child(4) .pricing__card-svg {
  transform: rotate(-45deg) scale(1.1);
}

.pricing__card {
  background-color: #fff;
  border: 1px solid var(--primary);
  padding: 60px 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  transition: background-color 0.4s ease, color 0.4s ease, fill 0.4s ease, border 0.4s ease;
}

.pricing__card-svg {
  margin-bottom: 20px;
  height: 50px;
  width: auto;
  fill: var(--primary) !important;
  color: var(--primary) !important;
  transition: background-color 0.4s ease, color 0.4s ease, fill 0.4s ease, border 0.4s ease;
}

.pricing__card-svg path {
  fill: var(--primary) !important;
}

.pricing__card-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 25px;
}

.pricing__card-price-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 45px;
}

.pricing__card-currency {
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  padding-top: 4px;
  color: var(--primary);
}

.pricing__card-price {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.3px;
  color: var(--primary);
  white-space: nowrap;
}

.pricing__card-price span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #222;
}

.pricing__item:nth-child(1) .pricing__card-options-item {
  font-weight: 700;
}

.pricing__card-options {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing__card-options-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 17px;
  border-bottom: 1px solid #727272;
  color: #333;
  transition: color 0.3s ease, border-color 0.3s ease;
  width: 100%;
}

.pricing__card-options-item span {
  display: block;
  width: 100%;
}

.pricing__card-options-item svg {
  width: 20px;
  height: 20px;
  fill: green;
}

.pricing__card-options-item svg.not {
  width: 26px;
  height: 26px;
  fill: gray;
}

.pricing__card-options-item:last-child {
  border-bottom: none;
}

.pricing__card-cta-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  text-decoration: none;
  padding: 12px 40px;
  background-color: var(--primary);
  color: #fff;
  border: 1px solid transparent;
  transition: border 0.3s ease;
  border-radius: 12px;
}

.pricing__card-cta-link:hover {
  text-decoration: underline;
}

.pricing__item:hover:nth-child(2) .pricing__card,
.pricing__item:hover:nth-child(3) .pricing__card,
.pricing__item:hover:nth-child(4) .pricing__card {
  background-color: var(--primary);
  color: #fff;
}

.pricing__item:hover:nth-child(2) .pricing__card-svg path,
.pricing__item:hover:nth-child(3) .pricing__card-svg path,
.pricing__item:hover:nth-child(4) .pricing__card-svg path {
  fill: #fff !important;
}

.pricing__item:hover:nth-child(2) .pricing__card-price span,
.pricing__item:hover:nth-child(3) .pricing__card-price span,
.pricing__item:hover:nth-child(4) .pricing__card-price span {
  color: #fff !important;
}

.pricing__item:hover:nth-child(2) .pricing__card-options-item,
.pricing__item:hover:nth-child(3) .pricing__card-options-item,
.pricing__item:hover:nth-child(4) .pricing__card-options-item {
  border-color: #fff;
  color: #fff;
}

.pricing__item:hover:nth-child(2) .pricing__card-options-item svg,
.pricing__item:hover:nth-child(3) .pricing__card-options-item svg,
.pricing__item:hover:nth-child(4) .pricing__card-options-item svg {
  fill: #fff;
}

.pricing__item:hover:nth-child(2) .pricing__card-cta-link,
.pricing__item:hover:nth-child(3) .pricing__card-cta-link,
.pricing__item:hover:nth-child(4) .pricing__card-cta-link {
  border: 1px solid #fff;
}

.pricing__item:hover:nth-child(2) .pricing__card-price,
.pricing__item:hover:nth-child(3) .pricing__card-price,
.pricing__item:hover:nth-child(4) .pricing__card-price {
  color: #fff;
}

.pricing__item:hover:nth-child(2) .pricing__card-price span,
.pricing__item:hover:nth-child(3) .pricing__card-price span,
.pricing__item:hover:nth-child(4) .pricing__card-price span {
  color: rgba(255, 255, 255, 0.6);
}

.pricing__item:hover:nth-child(2) .pricing__card-currency,
.pricing__item:hover:nth-child(3) .pricing__card-currency,
.pricing__item:hover:nth-child(4) .pricing__card-currency {
  color: #fff;
}

.pricing__item:first-child {
  flex-grow: 1;
}

.pricing__item .pricing__card-options-item:nth-child(2) {
  border-bottom: 3px solid #222;
}

.pricing__item .pricing__card-options-item:nth-child(6) {
  border-bottom: 3px solid #222;
}

@media (max-width: 1070px) {
  .pricing__item {
    width: calc((100% - 40px) / 3);
  }

  .pricing__item:first-child {
    display: none;
  }

  .pricing__card-price {
    font-size: 20px;
  }

  .pricing__card-options {
    display: none;
  }
}

@media (max-width: 767px) {
  .pricing {
    padding: 60px 0;
  }

  .pricing__list {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .pricing__item {
    width: 100%;
    max-width: 340px;
  }

  .pricing__item:first-child {
    display: none;
  }
}

.seventh {
  padding: 145px 0;
}

.seventh__title {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .seventh__title {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .seventh__title {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 0.18px;
  }
}

.seventh__title span {
  color: var(--primary);
}

.seventh__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.seventh__item {
  width: calc((100% - 160px) / 5);
  padding: 15px 30px;
  background-color: rgba(224, 224, 224, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* filter: grayscale(1) opacity(0.6); */
  transition: all 0.7s ease;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: 0;
  transition: box-shadow 1s;
  cursor: pointer;
}

.seventh__item span {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.seventh__item img {
  width: auto !important;
  max-height: 70px;
}

.seventh__item img:hover {
  filter: none;
}

.seventh__item:nth-child(4) {
  padding: 0;
}

.seventh__item:nth-child(4) img {
  max-height: 100%;
}

.seventh__item:nth-child(5) {
  padding: 0;
}

.seventh__item:nth-child(5) img {
  height: 90px;
}

.seventh__item:hover {
  filter: none;
  box-shadow: 0px 4px 21px -1px rgba(201, 201, 201, 0.81);
}

@media (max-width: 1260px) {
  .seventh__list {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }

  .seventh__item {
    width: calc((100% - 60px) / 4);
  }
}

@media (max-width: 1100px) {
  .seventh__item {
    width: calc((100% - 40px) / 3);
  }

  .form-section__container {
    display: block;
  }
}

@media (max-width: 767px) {
  .seventh {
    padding: 60px 0;
  }

  .seventh__item {
    width: calc((100% - 40px) / 2);
    filter: none;
  }

  .seventh__item img {
    width: 100%;
    height: auto;
  }
}

.myCheckbox {
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: auto;
}

.myCheckbox:after {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('./images/check.svg');
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}

.checkedInput svg {
  color: green !important;
  fill: green !important;
}

/*# sourceMappingURL=index.css.map */